<template>
  <LanguageSelect
    v-if="show"
    v-model="activeLocale"
    :locales="availableLocale"
    allow-blank
    hide-selected-empty
    :label="$t('HEADER_BAR.SELECT_MOTHER_TONGUE')"
  />
</template>

<script setup lang="ts">
import LanguageSelect from '@/components/language-select/LanguageSelect.vue';
import { computed } from 'vue';
import { Language } from '@/i18n';
import useLocalization from '@/services/useLocalization';
import useLocalStorage from '@/services/useLocalStorage';

const { setSecondaryLocale } = useLocalStorage();
const { locale, localeUi, setLocale, availableLocale } = useLocalization();
const isBaseLanguage = (language: Language) => Object.values(Language).includes(language);

const activeLocale = computed({
  get: () => (!isBaseLanguage(locale.value) ? locale.value : ''),
  set: (value: Language | '') => {
    setLocale(value === '' ? localeUi.value : (value as Language));
    setSecondaryLocale(value === '' ? localeUi.value : (value as Language))
  },
});

const show = computed(() => availableLocale.value.length > 0);
</script>
