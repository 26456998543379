import { mapPage } from '@/mapper/Page';
import {
  LearningPathDetailsResponse,
  LearningPathResponse,
  TempLearningPathDetailsResponse,
} from '@/model/response/LearningPath';
import { LearningPathModel } from '@/model/page/LearningPath';
import { mapContent } from '@/mapper/Content';
import { ImageModel } from '@/model/content/Image';
import { applyTranslations } from '@/mapper/utils/applyTranslations';

export const mapLearningPath = (
  response: LearningPathResponse,
  extraDataResponse: TempLearningPathDetailsResponse,
): LearningPathModel => {
  return {
    ...mapPage(response),
    ...mapExtraData(extraDataResponse.learningPath.data),
  };
};

const mapBanner = (content): ImageModel => ({
  altText: content?.bannerAlt ?? '',
  assetId: content?.bannerId ?? '',
  copyright: content?.bannerCopyright ?? '',
  caption: content?.bannerCaption ?? '',
});

const mapExtraData = (pageResponse: LearningPathDetailsResponse) => {
  const response = applyTranslations(pageResponse, pageResponse.localization?.data?.content);
  const content = applyTranslations(response.metadata, response.localization?.data?.content);

  return {
    children:
      response.elements
        ?.filter((item) => item.data?.content?.contentType)
        .map((item) => mapContent(item.data?.content)) ?? [],
    // TODO: the same as id from mapPage(), can be removed
    contentId: response.id,
    description: content?.description ?? '',
    teacherDescription: content?.descriptionInstructor ?? '',
    time: content?.duration,
    ...(response.metadata?.bannerId ? { bannerImage: mapBanner(content) } : {}),
  };
};
