import { NamespaceResponse } from '@/model/response';
import { Namespace } from '@/model/Namespace';
import {
  Content as SettingsResponse,
  ContentCardVariants,
  LanguagesResponse,
  LevelsSection,
  Menu,
} from '@/model/response/Settings';
import { Level, LevelSettings, Settings } from '@/model/Settings';
import { ColorTheme, Theme, ThemeMetadata } from '@/model/Theme';
import HeaderNavigation from '@/model/HeaderNavigation';
import { buildTree, FlatTree } from '@/utils/tree';
import { initFilterOptions } from '@/mapper/namespace/InitFilterOptions';
import { initFiltersObject } from '@/mapper/namespace/InitFiltersObject';
import { SkillType } from '@/config/Skills';
import { applyTranslations } from '@/mapper/utils/applyTranslations';
import usePermissions from '@/services/usePermissions';
import { generateLinkHref } from '@/utils/generateLinkHref';
import { Language } from '@/i18n';
import { Project, ProjectMetadata } from '@/core';

const DEFAULT_MAX_LEVEL = 3;
export const NO_LEVELS_LEVEL: Level = { id: 'NoLevel', value: null };
const { checkPermissions } = usePermissions();

export const resolveLanguages = (languages: LanguagesResponse) => {
  return languages?.filter(languageResponse => !languageResponse.hidden).map(languageData => languageData.locale)
};

export const mapNamespace = (response: NamespaceResponse): Namespace => {
  const settings = applyTranslations(
    response.namespace.data.configContent.data.content,
    response.namespace.data.configContent.data.localization,
  );

  return {
    id: response.namespace.data.id,
    name: response.namespace.data.name,
    languages: resolveLanguages(response.namespace.data.languages),
    defaultLanguage: findDefaultLanguage(response.namespace.data.languages),
    settings: mapSettings(settings),
    themeMetadata: {
      ...mapThemeMetadata(settings, response.namespace.data.metadata),
    },
    configId: response.namespace.data.config.setupContentId,
  };
};

export const mapNamespaceWithConfig = (response: Project<SettingsResponse>): Namespace => {
  const settings = applyTranslations(response.config.content, response.config.localization);

  return {
    id: response.id,
    name: response.name,
    languages: resolveLanguages(response.languages),
    defaultLanguage: findDefaultLanguage(response.languages),
    settings: mapSettings(settings),
    themeMetadata: {
      ...mapThemeMetadata(settings, response.metadata),
    },
    configId: response.configId,
    allowOpenAccess: response.isAnonymousUserAllowed || false,
  };
};

export const findDefaultLanguage = (languagesResponse: LanguagesResponse): Language => {
  return languagesResponse.find((languageData) => languageData.fallback).locale as Language;
};

export const mapSettings = (response: SettingsResponse): Settings => {
  const resolveLevels = (levelsSection: LevelsSection): LevelSettings => {
    const type = levelsSection?.levels?.useType || '';
    const currentLevels = (levelsSection?.levels?.list || [])
      .filter((item) => item.type === type)
      .map((item) => {
        return {
          id: item.value,
          value: item.name,
        };
      });

    return {
      levels: [NO_LEVELS_LEVEL, ...currentLevels],
      maxLevel: currentLevels.length ?? DEFAULT_MAX_LEVEL,
      useLevels: type ? levelsSection?.useLevels : false,
      levelType: type,
    };
  };

  return {
    favicon: response?.favicon?.imageId,
    ...resolveLevels(response?.levelsSection),
    appLogo: {
      altText: response?.appLogo?.altText || '',
      showName: response?.appLogo?.showName,
      desktopLogo: response?.appLogo?.desktopLogo,
      mobileLogo: response?.appLogo?.mobileLogo,
      tabletLogo: response?.appLogo?.tabletLogo,
    },
    footerNavigation: mapMenu(selectMenuData(response, 'footer')),
    headerNavigation: mapMenu(selectMenuData(response, 'main')),
    logoutURL: response?.customUrls?.logoutRedirectUrl,
    cardStyle: response?.cardStyle?.value || ContentCardVariants.SIMPLE,
    filters: initFiltersObject(response?.filters),
    categories: initFilterOptions(response?.categories?.list || []),
    skills: skillsList,
    useTeacherDashboard: Boolean(response?.teacherDashboard?.enableTeacherDashboard),
    title: response?.site?.title,
    productId: response?.productAccess?.productId,
    ...(response?.userFallbackAfterLogin ? { userFallbackAfterLogin: response?.userFallbackAfterLogin } : {}),
  };
};

const mapThemeMetadata = (
  responseContent: SettingsResponse,
  responseMetadata: Omit<ProjectMetadata, '_id'>,
): ThemeMetadata => {
  if (!responseContent || !responseMetadata) {
    console.error('Namespace data needed, please ensure that project is set up correctly');
  }

  return {
    color: (responseContent?.colorTheme?.value ?? ColorTheme.TEAL) as ColorTheme,
    theme: responseMetadata.theme as Theme,
    template: responseMetadata.template,
  };
};

export const selectMenuData = (response: SettingsResponse, type: string): Menu =>
  response?.menus?.menuItems?.find((item) => item.type === type);

export const mapMenu = (menu?: Menu): HeaderNavigation[] => {
  const nodes = menu
    ? menu.items.filter(checkPermissions).map(
        (item): FlatTree<HeaderNavigation> => ({
          node: {
            name: item.name,
            path: generateLinkHref(item?.contentType, item.url),
            contentType: item?.contentType,
            external: item.type === 'external',
          },
          id: item._id,
          order: item.order,
          parent: item.parent,
        }),
      )
    : [];

  return buildTree(nodes, '');
};

const skillsList = Object.values(SkillType);
