import { type ComputedRef, ref, Ref, computed } from 'vue';
import { LocaleCollection } from '@/model/response';
import useLocalization from '@/services/useLocalization';
import { Language } from '@/i18n';
import useLocalStorage from '@/services/useLocalStorage';
import useNamespace from '@/services/useNamespace';

interface useLocalLanguage {
  locales: ComputedRef<string[]>;
  activeLocale: Ref<string>;
  setActiveLocale: (newLocale: string) => void;
}

const activeLocale = ref<Language>(null);

export default (translationLocales: LocaleCollection = []): useLocalLanguage => {
  const { locale, availableLocale, availableLocaleUi } = useLocalization();
  const { getCurrentLocale, getSecondaryLocale, setSecondaryLocale } = useLocalStorage();
  const secondaryLocale = getSecondaryLocale();
  const { namespace } = useNamespace();

  const allLocales = computed(() => [...availableLocaleUi.value, ...availableLocale.value]);
  const locales = computed(() => [
    ...new Set([
      ...translationLocales.filter((locale) => allLocales.value.includes(locale)),
      namespace.defaultLanguage,
    ]),
  ]);

  const setActiveLocale = (newLocale: Language) => {
    activeLocale.value = newLocale;
    setSecondaryLocale(newLocale);
  };

  activeLocale.value = translationLocales.includes(secondaryLocale)
    ? secondaryLocale
    : getCurrentLocale() || locale.value;

  return {
    locales,
    activeLocale,
    setActiveLocale,
  };
};
