import { mapPage } from '@/mapper/Page';
import { TestResponse } from '@/model/response';
import { TestModel } from '@/model/page/Test';
import { applyTranslations } from '@/mapper/utils/applyTranslations';

export const mapTest = (pageResponse: TestResponse): TestModel => {
  const response = applyTranslations(pageResponse, pageResponse.localization?.data?.content);
  const content = applyTranslations(response.content?.data?.content, response.content?.data?.localization);

  return {
    ...mapPage(pageResponse),
    metadataContentImage: {
      altText: content?.bannerImage?.altText,
      assetId: content?.bannerImage?.imageId,
      copyright: content?.bannerImage?.copyright,
    },
    time: content?.duration ? parseReadingTime(content.duration) : null,
    feedback: {
      correct: content?.feedback?.correct,
      wrong: content?.feedback?.wrong,
    },
    showAnswers: content?.options?.showAnswers,
    passCriteriaScore: content?.passCriteria?.score,
    relatedContent: content?.relatedContent?.items[0],
  };
};

const parseReadingTime = (originalValue: number): string => originalValue.toString(10);
