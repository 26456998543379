export enum ColorTheme {
  AZURE_BLUE = 'azure-blue',
  BROWNIE = 'brownie',
  GREEN = 'green',
  ORANGE = 'orange',
  PRECISE_PURPLE = 'precise-purple',
  PURPLE = 'purple',
  TEAL = 'teal',
  YELLOW = 'yellow',
  ULTRAMARINE = 'ultramarine',
  RED = 'red',
  BLUE_PURPLE = 'sea-blue-precise-purple',
  PURPLE_BLUE = 'precise-purple-sea-blue',
  TEACHER = 'teacher',
  SEA_GREEN = 'sea-green',
  SEA_BLUE = 'sea-blue',
  SEA_GREEN_ORANGE = 'sea-green-orange',
  ORANGE_SEA_GREEN = 'orange-sea-green',
  TEAL_YELLOW = 'teal-yellow',
  YELLOW_TEAL = 'yellow-teal'
}

export enum Theme {
  ADVANCED = 'advanced',
  SIMPLE = 'simple',
  MEDIUM = 'medium',
  TEACHER = 'teacher',
}

export const defaultTheme = Theme.ADVANCED;

export interface ThemeMetadata {
  theme: Theme;
  template: string;
  color: ColorTheme;
}
