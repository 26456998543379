import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import i18n, { Language } from '@/i18n';
import config from '@/config';
import { createHead } from '@vueuse/head';
import { createGtm } from '@gtm-support/vue-gtm';
import useLocalStorage from '@/services/useLocalStorage';
import { mdHtml } from '@/core/directives';
import VueFocusLoop from '@vue-a11y/focus-loop';
import sticky from '@/utils/sticky';
import katex from '@/utils/katex';
import useNamespaceService from '@/services/useNamespace';
import { TenantConfig } from '@/config/tenants/TenantConfig';
import { getTenantConfig } from '@/config/getTenantConfig';
import auth from '@/core/auth';
import { useAuth } from '@/core';
import { VueQueryPlugin } from '@tanstack/vue-query';
import { localePlugin } from '@/utils/localePlugin';
import textDirection from '@/utils/textDirection';

const { setToken, setUser, login } = useAuth();

// TODO: uncomment below line(s) when adding PWA
// import './registerServiceWorker';

const head = createHead();

(async () => {
  const { getUiLocale, getCurrentLocale } = useLocalStorage();
  const { namespace, initNamespace } = useNamespaceService();
  const locale = getCurrentLocale();
  const localeUi: Language = getUiLocale();

  let tenantConfig: TenantConfig;

  try {
    await auth.checkToken();
    setToken();
    setUser();
  } catch (e) {
    if (e.message !== 'access token not found') {
      console.debug('User token not valid', e.message);
      login();
    } else {
      console.debug('User not available yet');
    }
  }

  try {
    tenantConfig = await config();
    await initNamespace(locale, { namespace: tenantConfig.namespace });
    console.debug('Loading configuration from config');
  } catch (_) {
    try {
      await initNamespace(locale, { domain: window.location.host });
      tenantConfig = getTenantConfig(namespace);
      console.debug('Loading configuration from service');
    } catch (error) {
      console.debug('Loading default configuration');
      tenantConfig = await config(true);
      await initNamespace(locale, { namespace: tenantConfig.namespace });
    }
  }

  i18n.global.locale.value = localeUi || locale || namespace.defaultLanguage || Language.NORWEGIAN_BOOKMAL;

  const app = createApp(App, {
    tenantConfig,
  })
    .directive('md-html', mdHtml)
    .directive('sticky', sticky)
    .directive('katex', katex)
    .directive('text-direction', textDirection)
    .use(head)
    .use(router)
    .use(i18n)
    .use(VueQueryPlugin)
    .use(VueFocusLoop)
    .use(localePlugin, {
      locale: locale || namespace.defaultLanguage || Language.NORWEGIAN_BOOKMAL,
      localeUi: i18n.global.locale.value,
    });

  if (process.env.VUE_APP_GTM_ID) {
    app.use(
      createGtm({
        id: process.env.VUE_APP_GTM_ID,
        vueRouter: router,
      }),
    );
  }

  app.mount('#app');
})();
