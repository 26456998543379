<template>
  <BaseSelect v-model:selected="selected" :list="items" :label="label">
    <template v-if="showIcon" #labelPrefix>
      <LanguageIcon class="c-base-select__label-language" />
    </template>
  </BaseSelect>
</template>

<script lang="ts" setup>
import BaseSelect from '@/elements/select/BaseSelect.vue';
import LanguageIcon from '@/assets/icons/LanguageIcon.vue';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

interface LanguageSelectProps {
  locales: string[];
  allowBlank?: boolean;
  showIcon?: boolean;
  hideSelectedEmpty?: boolean;
  label?: string;
}

const { t } = useI18n();

const convertToSelectItem = (locale: string) => ({
  name: t(`LANGUAGE.${locale}`),
  value: locale,
});

const props = defineProps<LanguageSelectProps>();

const model = defineModel();

const items = computed(() =>
  props.allowBlank
    ? [
        { value: '', name: `-${t('HEADER_BAR.DESELECT')}-`, hidden: model.value === '' && props.hideSelectedEmpty },
        ...props.locales.map(convertToSelectItem),
      ]
    : props.locales.map(convertToSelectItem),
);

const selected = computed({
  get: () => items.value.find((option) => option.value === model.value),
  set: (value) => (model.value = value.value),
});
</script>
