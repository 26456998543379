<template>
  <LanguageSelect v-model="activeLocale" :locales="localeList" show-icon />
</template>

<script setup lang="ts">
import { onMounted, watch, computed } from 'vue';
import { Language } from '@/i18n';
import { LocaleCollection } from '@/model/response';
import useLocalLanguage from '@/services/useLocalLanguage';
import LanguageSelect from '@/components/language-select/LanguageSelect.vue';

interface LanguageSwitchProps {
  locales: LocaleCollection;
}

const props = defineProps<LanguageSwitchProps>();
const emit = defineEmits<{
  change: [value: string];
}>();

const localLanguage = computed(() => useLocalLanguage(props.locales));
const activeLocale = computed({
  get: () => localLanguage.value?.activeLocale.value,
  set: (value: Language) => {
    localLanguage.value.setActiveLocale(value);
    return value;
  },
});

onMounted(() => emit('change', activeLocale.value));

watch(activeLocale, (newValue: string) => {
  emit('change', newValue);
});

const localeList = computed(() => localLanguage.value?.locales.value);
</script>
